import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useKey } from 'react-use'
import { language, getImageBySize } from '../../utils'
import Portal from './Portal'

export default function Photo({ className, style, index }) {
  const { locales } = useStaticQuery(graphql`
    {
      strapi {
        locales {
          language
          images {
            ext
            url
            formats
          }
        }
      }
    }
  `).strapi
  const locale = locales.find(l => l.language === language())

  const [zoom, setZoom] = useState(false)
  useKey('Escape', () => setZoom(false))
  if (!locale.images[index]) return null
  return (
    <>
      <div
        onClick={() => setZoom(!zoom)}
        className={`relative flex-shrink-0 items-center justify-center bg-white rounded shadow-md hover:shadow-lg border-8 border-b-40 border-white select-none cursor-pointer ${className}`}
        style={style}>
        {locale.images[index].ext === '.mp4' &&
          <video src={getImageBySize(locale.images[index])} className="max-w-full max-h-full pointer-events-none rounded" autoPlay loop muted playsInline />
        }
        {locale.images[index].ext !== '.mp4' &&
          <img src={getImageBySize(locale.images[index], 'medium')} className="max-w-full max-h-full pointer-events-none rounded" />
        }
      </div>
      {zoom &&
        <Portal>
          <div
            onClick={() => setZoom(!zoom)}
            className="fixed select-none top-0 left-0 w-screen h-screen flex justify-center p-4 lg:p-10 items-center cursor-pointer z-30 bg-black bg-opacity-75">
            {locale.images[index].ext === '.mp4' &&
              <video src={getImageBySize(locale.images[index])} className="rounded shadow-md hover:shadow-lg border-8 border-white max-h-full" autoPlay loop muted playsInline />
            }
            {locale.images[index].ext !== '.mp4' &&
              <img src={getImageBySize(locale.images[index], 'large')} className="rounded shadow-md hover:shadow-lg border-8 border-white max-h-full" />
            }
          </div>
        </Portal>
      }
    </>
  )
}